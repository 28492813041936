<h2 md-dialog-title>{{contest.title}}</h2>
<md-dialog-content>
    <table>
        <tr>
            <th>Liczba voucherów</th>
            <td>{{voucherInfo?.all}}</td>
        </tr>
        <tr>
            <th>Dostępne</th>
            <td>{{voucherInfo?.unused}}</td>
        </tr>
        <tr>
            <th>Zużyte</th>
            <td>{{voucherInfo?.used}}</td>
        </tr>
    </table>
</md-dialog-content>
<md-dialog-actions>
    <div class="btn__group u--text-right u--width-100">
        <button md-dialog-close md-raised-button [disabled]="pending" color="primary">
            <md-progress-spinner mode="indeterminate" color="accent"
                                 class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                 [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>
            Zamknij
        </button>
    </div>
</md-dialog-actions>`
