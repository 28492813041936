<form [formGroup]="form" (submit)="onSubmit()">
    <div>
        <md-input-container class="u--full-width">
            <input name="oldPassword"
                   type="password"
                   id="old-password"
                   formControlName="oldPassword"
                   mdInput
                   placeholder="{{ 'OLD_PASSWORD'|translate }}"
            >
        </md-input-container>
        <app-error-message [control]="form.controls['oldPassword']"
                           [apiError]="formErrorrs?.oldPassword"></app-error-message>
    </div>
    <div>
        <md-input-container class="u--full-width">
            <input name="password"
                   type="password"
                   id="password"
                   formControlName="new_password1"
                   mdInput
                   placeholder="{{ 'NEW_PASSWORD'|translate }}"
            >
        </md-input-container>
        <app-error-message [control]="form.controls['new_password1']"
                           [apiError]="formErrorrs?.new_password1"></app-error-message>
    </div>
    <div>
        <md-input-container class="u--full-width">
            <input name="confirm"
                   type="password"
                   id="confirm"
                   formControlName="new_password2"
                   mdInput
                   placeholder="{{ 'CONFIRM_NEW_PASSWORD'|translate }}"
            >
        </md-input-container>
        <app-error-message [control]="form.controls['new_password2']"
                           [apiError]="formErrorrs?.new_password2"></app-error-message>
    </div>

    <app-error-message [control]="form" [apiError]="formErrorrs?.non_field_errors"></app-error-message>
    <div class="form-group">
        <div class="btn__group u--text-right">
            <button md-button md-dialog-close color="warn">{{ 'CANCEL'|translate }}</button>
            <button color="primary" md-raised-button type="submit" [disabled]="pending">
                <md-progress-spinner mode="indeterminate" color="accent"
                                     class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                     [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>
                {{ 'SUBMIT'|translate }}
            </button>
        </div>
    </div>
</form>
