<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" [formGroup]="filterForm">
    <div>
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start center" [formGroup]="filterForm" fxLayoutGap="10px">
            <div fxFlex="210px">
                <md-input-container>
                    <input type="text" mdInput placeholder="Szukaj kategorii"
                           formControlName="text">
                </md-input-container>
            </div>
        </div>
    </div>
    <div>
        <button class="" md-button (click)="onClearClick()" *ngIf="filterForm?.dirty" color="warn">{{'CLEAR'
            |translate}}
        </button>
    </div>
</div>
