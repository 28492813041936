<a class="question_prev__toggle" (click)="toggleSize()" md-button md-raised-button color="primary">
    <md-icon>cached</md-icon>{{size}}
</a>
<section class="question_prev">

    <div class="question_prev__header">
    </div>
    <div class="question_prev__content" [ngClass]="{'question_prev--img': hasImg(), 'question_prev--sm' : size ==='4:3'}">
        <div class="question_prev__wrapper" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div>
                <div class="question_prev__user" fxLayout="row" fxLayoutAlign="start center">
                    <div class="question_prev__user-icon">
                        <md-icon>perm_identity</md-icon>
                    </div>
                    <div class="question_prev__user-details">
                        <p>Name Surname</p>
                        <span></span> <span></span> <span></span> <span></span> <span></span> <span></span>
                    </div>
                </div>
            </div>
            <div fxFlex="auto" class="question_prev__sf-fix">
                <div>
                    <div class="question_prev__question u--height-100" fxLayout="column" fxLayoutAlign="center center">
                        <p >{{question?.text}}</p>
                    </div>
                </div>

            </div>
            <div class="question_prev__img" *ngIf="question?.photo_url" fxFlex>
                <img src="{{question?.photo_url}}" alt="">
            </div>
            <div class="question_prev__time">
                <div class="question_prev__time-inner" [ngStyle]="{'width': randomWidth}"></div>
            </div>
            <div class="question_prev__answers" [ngStyle]="{'font-size.px': fontSize}">

                <div *ngFor="let item of question?.answers" class="question_prev__answer-wrapper">
                    <div class="question_prev__answer" fxLayout="column" fxLayoutAlign="center center">
                        {{item.text}}
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="question_prev__footer">

    </div>
</section>
