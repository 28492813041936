<section [@slideFadeLeft] class="sidenav" [ngClass]="{'sidenav--rolled-up': !rolledUp}">
    <div class="sidenav--logo u--shadow">
        <div>
        </div>
    </div>
    <ul class="sidenav__links">
        <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <md-icon color="primary">home</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.DASHBOARD'|translate}}</span></a></li>
        <li><a routerLink="/questions" routerLinkActive="active">
            <md-icon>star_rate</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.QUESTIONS'|translate}}</span></a></li>
        <li><a routerLink="/contests" routerLinkActive="active">
            <md-icon>local_activity</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.CONTESTS'|translate}}</span></a></li>
        <li><a routerLink="/users" routerLinkActive="active">
            <md-icon>people</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.USERS'|translate}}</span></a></li>
        <li><a routerLink="/administrators" routerLinkActive="active">
            <md-icon>people</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.ADMINISTRATORS'|translate}}</span></a></li>
        <li><a routerLink="/categories" routerLinkActive="active">
            <md-icon>view_list</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.CATEGORIES'|translate}}</span></a></li>
        <li><a routerLink="/import-categories" routerLinkActive="active">
            <md-icon>file_upload</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.IMPORTS'|translate}}</span></a></li>
        <li><a routerLink="/export-categories" routerLinkActive="active">
            <md-icon>file_download</md-icon>
            <span class="sidenav__link-desc">{{'ROUTES.EXPORTS'|translate}}</span></a></li>
    </ul>
</section>
