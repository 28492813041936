<div class="u--shadow list__view filters">
    <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
        <div>
            <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" [formGroup]="filterForm">
                <div>
                    <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start center" [formGroup]="filterForm" fxLayoutGap="30px">
                        <div fxFlex="200px">
                            <app-category-autocomplete-form formControlName="category"></app-category-autocomplete-form>
                            <app-error-message [control]="filterForm.controls['category']"></app-error-message>
                        </div>
                        <div fxFlex="110px">
                            <md-select placeholder="{{ 'RELEASE'|translate }}" formControlName="is_active">
                                <md-option [value]="null">{{'ALL'|translate}}</md-option>
                                <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}
                                </md-option>
                            </md-select>

                        </div>
                        <div fxFlex="110px">
                            <md-select placeholder="{{ 'CONTAINS_NOTES'|translate }}" formControlName="contains_notes">
                                <md-option [value]="null">{{'ALL'|translate}}</md-option>
                                <md-option value="true">{{'WITH_NOTES'|translate}}</md-option>
                                <md-option value="false">{{'WITHOUT_NOTES'|translate}}</md-option>
                            </md-select>
                        </div>
                        <div fxFlex="110px">
                            <md-select placeholder="{{ 'ADDED_BY_USERS'|translate }}" formControlName="added_by_user">
                                <md-option [value]="null">{{'ALL'|translate}}</md-option>
                                <md-option value="true">{{'YES'|translate}}</md-option>
                                <md-option value="false">{{'NO'|translate}}</md-option>
                            </md-select>
                        </div>
                        <button class="" md-button (click)="onClearClick()" *ngIf="filterForm?.dirty" color="warn">{{'CLEAR'
                            |translate}}
                        </button>
                        <button md-button md-raised-button class="new-question-btn" color="primary"
                        [disabled]="isExportBtnDisabled" (click)="onDisplayExportFile()">
                        <md-progress-spinner mode="indeterminate" color="accent"
                                                class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                                [ngClass]="{'md-spinner--show': isPending}"></md-progress-spinner>
                        Podgląd
                    </button>
                        <button md-button md-raised-button class="new-question-btn" color="primary"
                        [disabled]="isExportBtnDisabled" (click)="onExportFile()">
                        <md-progress-spinner mode="indeterminate" color="accent"
                                                class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                                [ngClass]="{'md-spinner--show': isPending}"></md-progress-spinner>
                            Eksportuj
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <table class="table table--align-left table--white" *ngIf="collection">
        <thead>
        <tr>
            <th>{{'QUESTION'|translate}}</th>
            <th>{{'IMAGE'|translate}}</th>
            <th>{{'ANSWERS'|translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let category of collection; let i = index">
            <td>
                {{category.text}}
            </td>
            <td>
                <strong>Autor:</strong> {{category.photo_author}} <br>
                <strong>Zdjęcie:</strong> {{category.photo_url}}
            </td>
            <td>
                <ul>
                    <li *ngFor="let answer of category.answers">
                        {{answer.text}}
                    </li>
                </ul>

            </td>
        </tr>
        </tbody>
    </table>
</div>
