<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-betwen stretch" fxFlexFill class="u--height-100vh">
    <div fxFlex="0 1 auto" class="u--z-index-10 u--height-100 u--height-100vh">
        <app-sidenav [rolledUp]="menuIsOpen"></app-sidenav>
    </div>
    <div fxFlex class="u--z-index-1 u--height-100 u--height-100vh">
        <div fxLayout="column" fxLayoutWrap="nowrap" fxLayoutAlign="space-betwen stretch" fxFlexFill class="u--height-100vh">
            <header fxFlex="64px" class="u--z-index-10">
                <app-nav-toolbar [menuIsOpen]="menuIsOpen" (onMenuClick)="toggleSliderBar()"></app-nav-toolbar>
            </header>
            <div fxFlex class="home--inner u--height-100">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <router-outlet name='modal'></router-outlet>
</div>
