<md-card>
    <div class="comments-title">{{ 'COMMENTS'|translate }}</div>

    <div class="comments-list">
        <p *ngFor="let comment of comments">{{ comment.text }}</p>
    </div>

    <div class="u--text-right">
        <button class="btn-flat" *ngIf="comments?.length" (click)="clearComments()">Wyczyść</button>
    </div>
</md-card>
