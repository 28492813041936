<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
<div class="u--shadow list__view u--bg-white">
    <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
        <div>
            <app-admins-filter (onFilterChange)="onFilterChange($event)"></app-admins-filter>
        </div>
        <div fxFlexAlign="right" class="u--text-right">
            <a md-button md-raised-button routerLink="/administrators/add-new" class="new-question-btn" color="primary"><md-icon class="md-icon--sm">add</md-icon>{{ 'ADD_ADMIN'|translate }}</a>
        </div>
    </div>
    <table class="table table--white ">
        <thead class="table__head u--text-left">
        <tr>
            <th>{{'NAME'|translate}}</th>
            <th>{{'EMAIL'|translate}}</th>
            <th colspan="2">{{'ACTIONS'|translate}}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr  *ngFor="let item of collection| paginate: { itemsPerPage:pagination.limit, totalItems:collectionCount, currentPage: page }; let i = index" app-admin-row [user]="item"
             (onDelete)="onDeleteUserClick($event)"></tr>
        </tbody>
    </table>
    <app-pagination *ngIf="isInit" [itemsPerPage]="pagination.limit"
                    (onPageChange)="onPageChange($event)"
                    (onNumPagesChange)="onNumPagesChange($event)"></app-pagination>
    <router-outlet></router-outlet>
</div>



