<td class="table__td">
    {{user.name}}

</td>
<td>
    {{ user.email }}
</td>
<td class="table__td" [ngSwitch]="user.is_active">
    <md-checkbox
            [checked]="user.is_active"
            color="primary"
            [disabled]="loggedUser.id === user.id"
            (change)="toggleUserActivity($event)"
    >
        <span *ngSwitchCase="true">{{ 'ACTIVE'|translate }}</span>
        <span *ngSwitchCase="false">{{ 'INACTIVE'|translate }}</span>
    </md-checkbox>
</td>
<td class="table__td">
    <md-icon class="u--pointer" (click)="deleteAdmin()" *ngIf="loggedUser.id !== user.id">delete</md-icon>
</td>
