<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
<div class="u--shadow list__view u--bg-white">
  <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
    <div>
      <app-contest-filter (onFilterChange)="onFilterChange($event)">
        <!--<md-select placeholder="{{ 'Sortowanie'|translate }}" (change)="sortChange($event)" [ngModel]="order?.order">-->
          <!--<md-option *ngFor="let active of sortList" [value]="active.value">{{ active.label|translate }}-->
          <!--</md-option>-->
        <!--</md-select>-->
      </app-contest-filter>
    </div>
    <div fxFlexAlign="right" class="u--text-right">
      <a md-button md-raised-button routerLink="/contests/add-new" class="new-question-btn" color="primary"><md-icon class="md-icon--sm">add</md-icon> Dodaj konkurs</a>
    </div>
  </div>
  <table class="table table--white ">
    <thead class="table__head u--text-left">
    <tr>
      <th>Tytuł</th>
      <th>Organizator</th>
      <th>Okładka</th>
      <th>Kategoria</th>
      <th>{{'CATEGORY_COLOR'|translate}}</th>
      <th>Start</th>
      <th>Koniec</th>
      <th colspan="2">{{'ACTIONS'|translate}}</th>
    </tr>
    </thead>
    <tbody class="table__body">
    <tr  *ngFor="let item of collection| paginate: { itemsPerPage:pagination.limit, totalItems:collectionCount, currentPage: page }; let i = index" app-contest-row [contest]="item"
          (onShowVoucher)="onShowVoucher($event)"
         (onDelete)="onDeleteClick($event)"></tr>
    </tbody>
  </table>
  <app-pagination *ngIf="isInit" [itemsPerPage]="pagination.limit"
                  (onPageChange)="onPageChange($event)"
                  (onNumPagesChange)="onNumPagesChange($event)"></app-pagination>
  <router-outlet></router-outlet>
</div>



