<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
<div class="u--shadow list__view filters">
    <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
        <div>
            <app-guestions-filters (onFilterChange)="onFilterChange($event)"></app-guestions-filters>
        </div>
        <div class="u--text-right">
            <a md-button md-raised-button color="primary" routerLink="/questions/add-new" class="new-question-btn">
                <md-icon class="md-icon--sm">add</md-icon>
                {{ 'ADD_QUESTION'|translate }}</a>
        </div>
    </div>
    <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
        <div>
            <md-select placeholder="{{ 'Sortowanie'|translate }}" (change)="sortChange($event)" [ngModel]="order?.order">
                <md-option *ngFor="let active of sortList" [value]="active.value">{{ active.label|translate }}
                </md-option>
            </md-select>
        </div>
        <div *ngIf="isSelected">
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div fxFlex="110px">
                    <md-select placeholder="{{ 'Akcja'|translate }}" [ngModel]='massActionType' (change)="actionChange($event)">
                        <md-option *ngFor="let active of actionList" [value]="active.value">{{ active.label|translate }}
                        </md-option>
                    </md-select>
                </div>
                <div *ngIf="massActionType ==='category'" fxFlex="110px">
                    <app-category-autocomplete-form  [(ngModel)]="massActionCategory"></app-category-autocomplete-form>
                </div>
                <div class="mb-10">
                    <button md-button md-raised-button color="primary" (click)="doMassActions()" [disabled]="btnIsDisabled" class="new-question-btn">Wykonaj <span>({{countSelectedItems}})</span></button>
                </div>
            </div>
        </div>
    </div>
    <div class="table table__flex table__flex table--align-left table--white table--editable">
        <div class="table__head">
            <div class="table__tr u--text-left" fxLayout="row" fxLayoutAlign="space-between center"
                 fxLayoutWrap="nowrap">
                <div class="table__td" fxFlex="45px">
                    <md-checkbox [(ngModel)]="checkAll" (change)="checkboxChange($event)"></md-checkbox>
                </div>
                <div class="table__th" fxFlex>{{'QUESTION'|translate}}</div>
                <div class="table__th" fxFlex="100px">{{'IMAGE'|translate}}</div>
                <div class="table__th" fxFlex="100px">{{'NOTES_COUNT'|translate}}</div>
                <div class="table__th" fxFlex="200px">{{'CATEGORY'|translate}}</div>
                <div class="table__th" fxFlex="100px">{{'RELEASE'|translate}}</div>
                <div class="table__th" fxFlex="255px"></div>
            </div>
        </div>
        <div class="table__body">
            <div *ngFor="let item of collection | paginate: { itemsPerPage:pagination.limit, totalItems:collectionCount, currentPage: page }; let i = index "
                 app-question-row [row]="item" (click)="onRowClick(i)" (onRowUpdate)="onRowUpdate($event)"></div>
        </div>
    </div>
    <app-pagination *ngIf="isInit" [itemsPerPage]="pagination.limit" (onPageChange)="onPageChange($event)"
                    (onNumPagesChange)="onNumPagesChange($event)"></app-pagination>
    <router-outlet></router-outlet>
</div>

