<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center"  [formGroup]="filterForm">
  <div>
    <md-input-container>
      <input type="text" mdInput placeholder="{{ 'SEARCH_USER'|translate }}"  formControlName="text">
    </md-input-container>
  </div>
  <div>
    <md-select placeholder="{{ 'CONTAINS_NOTES'|translate }}" formControlName="contains_reports">
      <md-option [value]="null">{{'ALL'|translate}}</md-option>
      <md-option value="true">{{'WITH_NOTES'|translate}}</md-option>
      <md-option value="false">{{'WITHOUT_NOTES'|translate}}</md-option>
    </md-select>
  </div>
  <div>
    <button class="" md-button (click)="onClearClick()" *ngIf="filterForm?.dirty" color="warn">{{'CLEAR' |translate}}</button>
  </div>
</div>
