<div class="u--shadow">
    <section class="import-file__view u--bg-white">
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px">
            <div fxFlex="250px">
                <app-group-category-autocomplete [placeholder]="'CATEGORY'|translate"
                                                 (categorySelected)="childAdded($event)"
                                                 filterType="editQuestion"></app-group-category-autocomplete>
                <app-error-message [control]="formGroup.controls['category']"></app-error-message>
            </div>
            <div fxFlex fxFlexFill>
                <div [ngSwitch]="uploader.queue.length > 0">
                    <div *ngSwitchCase="true">
                        <p class="selected-file__label">Wybrany plik: <a (click)="removeFile()">
                            <md-icon class="md-icon--sm">delete</md-icon>
                        </a></p>
                        <p class="selected-file">{{uploader.queue[0].file.name}} </p>
                    </div>
                    <div *ngSwitchDefault>
                        <div class="import-file__drag"
                             ng2FileDrop
                             [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                             (fileOver)="fileOverBase($event)"
                             [uploader]="uploader"
                        >
                            <span class="import-file__drag-txt">Przeciągnij plik lub</span>

                            <button md-button md-raised-button class="selectfile-btn" color="primary">
                                <md-icon class="md-icon--sm">add</md-icon>
                                {{ 'WYBIERZ'|translate }}
                                <label for="fileUpload">
                                    <input id="fileUpload" type="file" ng2FileSelect [uploader]="uploader">
                                </label>
                            </button>

                        </div>
                        <p class="file-error">{{fileError}}</p>
                        <app-error-message [control]="formGroup.controls['file']"></app-error-message>
                    </div>
                </div>

                <div class="import-file__progress" *ngIf="isPending">
                    <p>{{progress}}%</p>
                    <span [ngStyle]="{'width.%': progress}"></span>
                </div>
            </div>
            <button md-button md-raised-button class="new-question-btn" color="primary"
                    [disabled]="isSendBtnDisabled" (click)="onSendFile()">
                <md-progress-spinner mode="indeterminate" color="accent"
                                     class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                     [ngClass]="{'md-spinner--show': isPending}"></md-progress-spinner>
                Wyślij
            </button>
        </div>
        <div [ngSwitch]="currentStatus" class="import-file__status">
            <span *ngSwitchCase="IMPORT_STATUS.SUCCESS">
                <h2>Wszystkie pytania zostały wczytane</h2>
            </span>
            <span *ngSwitchCase="IMPORT_STATUS.NOT_ALL">
                <h2>Nie wszystkie pytania zostały wczytane</h2>
            <p>Poniższe pytania zawierały błędy i nie zostały dodane do bazy danych</p>
            </span>
            <span *ngSwitchCase="IMPORT_STATUS.ERROR">
                <h2>Podczas wczytywania pytań nastąpił błąd</h2>
            </span>
            <span *ngSwitchDefault></span>
        </div>
    </section>

    <table class="table table--align-left table--white" *ngIf="collection">
        <thead>
        <tr>
            <th>{{'QUESTION'|translate}}</th>
            <th>{{'IMAGE'|translate}}</th>
            <th>{{'ANSWERS'|translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let category of collection; let i = index">
            <td>
                {{category.text}}
            </td>
            <td>
                <strong>Autor:</strong> {{category.photo_author}} <br>
                <strong>Zdjęcie:</strong> {{category.photo_url}}
            </td>
            <td>
                <ul>
                    <li *ngFor="let answer of category.answers">
                        {{answer.text}}
                    </li>
                </ul>

            </td>
        </tr>
        </tbody>
    </table>
</div>
