<td class="first-image">
    <app-image-preview-with-upload [imgSrc]="rowForm.img?.value" type="'cat_sm'" [isEdit]="row.isEdit" (onEndItem)="onBigFileAdded($event)"></app-image-preview-with-upload>
    <app-error-message [control]="rowForm.img"
                       [apiError]="formErrorrs?.img"></app-error-message>
</td>
<td>
    <app-image-preview-with-upload [imgSrc]="rowForm.icon?.value" type="'cat_lg'" [isEdit]="row.isEdit" (onEndItem)="onFileAdded($event)"></app-image-preview-with-upload>
    <app-error-message [control]="rowForm.icon"
                       [apiError]="formErrorrs?.icon"></app-error-message>
</td>
<td>
    <app-image-preview-with-upload [imgSrc]="rowForm.banner_url?.value" type="'cat_lg'" [isEdit]="row.isEdit" (onEndItem)="onFileAdded($event, 'banner_url')"></app-image-preview-with-upload>
    <app-error-message [control]="rowForm.banner_url"
                       [apiError]="formErrorrs?.banner_url"></app-error-message>
</td>
<td class="td--name">
    <md-input-container>
        <input name="label"
               type="text"
               mdInput
               [formControl]="rowForm.banner_target_url"
        >
    </md-input-container>
    <app-error-message [control]="rowForm.banner_target_url"
                       [apiError]="formErrorrs?.banner_target_url"></app-error-message>
</td>
<td>
    <md-input-container class="color-picker__container">
       <span [style.background]="currentColor" class="color-picker__color-box"
             (click)="isColorPickerOpen = true"></span>
        <input mdInput type="text" [formControl]="rowForm.color" [(cpToggle)]="isColorPickerOpen"
               [cpFallbackColor]="'#FFF'" [colorPicker]="currentColor" (colorPickerChange)="onColorPickerChange($event)"
               [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'"/>
    </md-input-container>
    <app-error-message [control]="rowForm.color"
                       [apiError]="formErrorrs?.color"></app-error-message>
</td>
<td class="td--name">
    <md-input-container>
        <input name="label"
               type="text"
               mdInput
               [formControl]="rowForm.label"
        >
    </md-input-container>
    <app-error-message [control]="rowForm.label"
                       [apiError]="formErrorrs?.label"></app-error-message>
</td>

<td class="td--name">
    <md-input-container>
        <input name="label"
               type="text"
               mdInput
               [formControl]="rowForm.description"
        >
    </md-input-container>
    <app-error-message [control]="rowForm.description"
                       [apiError]="formErrorrs?.description"></app-error-message>
</td>
<td>
    <md-select [formControl]="rowForm.status" *ngIf="isInit">
        <md-option *ngFor="let status of catStates" [value]="status.value">{{ status.label|translate }}</md-option>
    </md-select>
    <app-error-message [control]="rowForm.status"
                       [apiError]="formErrorrs?.status"></app-error-message>
</td>
<td>
    <md-select [formControl]="rowForm.is_active" class="mat-short" *ngIf="isInit">
        <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}</md-option>
    </md-select>
    <app-error-message [control]="rowForm.is_active"
                       [apiError]="formErrorrs?.is_active"></app-error-message>
</td>
<td [ngSwitch]="row.is_grouped">
    <span *ngSwitchCase="true">
        <div *ngIf="!row.isEdit">
            <span class="u--text-nowrap">{{ row.subcategories.length|i18nPlural: categorieMapping | translate: {count:row.subcategories.length} }}</span>
        </div>
        <div *ngIf="row.isEdit">
    <md-chip-list selectable="false">
        <md-chip class="md-chip--small md-chip--ellipsis md-chip--icon" *ngFor="let child of subCatCopy;let i = index"
                 color="accent"
                 selected="true"><span>{{child.label}}</span><md-icon (click)="removeChild(i)">close</md-icon>
        </md-chip>
    </md-chip-list>
        <div>
            <app-group-category-autocomplete [category]="row" [subcategories]="subCatCopy"
                                            (categorySelected)="childAdded($event)"></app-group-category-autocomplete>
        </div>
        </div>
    </span>
    <span *ngSwitchDefault>{{'NO'|translate}}</span>
</td>
<td class="td--name">
    <md-input-container>
        <input name="slug"
               type="text"
               mdInput
               [formControl]="rowForm.slug"
        >
    </md-input-container>
    <app-error-message [control]="rowForm.slug"
                       [apiError]="formErrorrs?.slug"></app-error-message>
</td>


<td class="u--text-right table__actions">
    <span *ngIf="row.isEdit" class="btn__group">
        <md-icon class="u--pointer" (click)="onRemoveClick()">delete</md-icon>
    <button md-raised-button (click)="onRejectClick($event)" class="btn--sm">{{'REJECT'|translate}}</button>
    <button (click)="onSaveClick($event)" md-raised-button color="primary" class="btn--sm" [disabled]="pending">{{'SAVE'|translate}}</button>
    </span>
</td>
