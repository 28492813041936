<div class="table__tr" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutWrap="nowrap">
    <div class="table__td" fxFlex="45px" (click)="$event.stopPropagation()">
        <md-checkbox [(ngModel)]="row.checked"></md-checkbox>
    </div>
    <div class="table__td" fxFlex>
        <md-input-container>
            <input name="text"
                   type="text"
                   mdInput
                   [formControl]="rowForm.text"
            >
        </md-input-container>
        <app-error-message [control]="rowForm.text"
                           [apiError]="formErrorrs?.text"></app-error-message>
    </div>
    <div class="table__td" fxFlex="100px">
        <div>
            <app-image-preview-with-upload [imgSrc]="rowForm.photo_url?.value" [isEdit]="row.isEdit"
                                           (onEndItem)="onFileAdded($event)"></app-image-preview-with-upload>
            <app-error-message [control]="rowForm.photo_url"
                               [apiError]="formErrorrs?.photo_url"></app-error-message>
        </div>
    </div>
    <div class="table__td" fxFlex="100px">
        <div class="u--text-center" [ngSwitch]="!!row.notes_count">
            <span *ngSwitchCase="true"><md-icon>note</md-icon></span>
            <span *ngSwitchCase="false">-</span>
        </div>
    </div>
    <div class="table__td" fxFlex="200px">
        <app-group-category-autocomplete filterType="editQuestion"
                                         [category]="row.category_id"
                                         (categorySelected)="childAdded($event)">
        </app-group-category-autocomplete>
        <app-error-message [control]="rowForm.category_id"
                           [apiError]="formErrorrs?.category_id"></app-error-message>
    </div>
    <div class="table__td" fxFlex="100px">
        <md-select [formControl]="rowForm.is_active" class="mat-short">
            <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}
            </md-option>
        </md-select>
        <app-error-message [control]="rowForm.is_active"
                           [apiError]="formErrorrs?.is_active"></app-error-message>
    </div>
    <div class="table__td u--text-right table__actions" fxFlex="255px">
        <md-icon class="u--pointer u--delete-row" (click)="onDeleteClick($event)">delete</md-icon>
        <md-icon class="u--pointer u--delete-row" [routerLink]="['/questions', row.id]"
                 (click)="$event.stopPropagation()">remove_red_eye
        </md-icon>
        <span *ngIf="row.isEdit" class="btn__group">
    <button md-raised-button (click)="onRejectClick($event)" class="btn--sm">{{'REJECT'|translate}}</button>
    <button (click)="onSaveClick($event)" md-raised-button color="primary" class="btn--sm" [disabled]="pending">{{'SAVE'|translate}}</button>
    </span>
    </div>
</div>
<div qhNestyTableOpen [isOpen]="editMode" class="table__neasted-wrapper">
    <div class="table__neasted">
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutWrap="nowrap">
            <div fxFlex="50%" class="table__neasted-td">
                <div class="form-group" [formGroup]="rowForm.answers">
                    <div *ngFor="let answer of rowForm.answers.controls; let i=index">
                        <md-input-container class="block" [formGroupName]="i">
                            <input type="text" mdInput
                                   placeholder="{{ (i === 0 ? 'CORRECT_ANSWER' : 'ANSWER_NR')|translate:{value: i+1} }}"
                                   formControlName="text">
                        </md-input-container>
                        <app-error-message
                                [control]="rowForm.answers.controls[i]?.controls['text']"></app-error-message>
                    </div>
                </div>


            </div>
            <div fxFlex="50%" class="table__neasted-td">
                <app-comments *ngIf="row.isEdit" [rowId]="row.id"></app-comments>
            </div>
        </div>
    </div>
</div>

