<td>
    {{contest.title}}
</td>
<td>
    {{contest.organizer_name}}
</td>
<td>
    <div class="image-prev">
        <div class="image-prev__inner"
             [ngStyle]="{'background-image': contest.cover_url ? 'url('+( contest.cover_url)+')' : null}"></div>
    </div>
</td>
<td>
{{contest.categories[0].label}}
</td>
<td>
    <div class="color-picker__container">
    <span [style.background]="contest.color" class="color-picker__color-box"></span>
</div>
</td>
<td>
    {{contest.start_date|date:'dd.MM.yyyy'}}
</td>
<td>
    {{contest.end_date|date:'dd.MM.yyyy'}}
</td>
<td>
    <md-icon class="u--pointer u--delete-row" (click)="showAvailableCoupons()">remove_red_eye</md-icon>
    <md-icon class="u--pointer u--delete-row" (click)="delete()">delete</md-icon>
    <button  (click)="onEdit()" md-raised-button  class="btn--sm">Edytuj</button>
    <button (click)="onImportCsv()" md-raised-button color="primary" class="btn--sm">Import CSV</button>
</td>
