<div class="modal-form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isInit">
        <div class="modal-form__header">
            <input type="text" formControlName="label" placeholder="Nazwa kategorii">
            <app-error-message [control]="form.controls['label']"
                               [apiError]="formErrorrs?.label"></app-error-message>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="form-group" fxLayoutGap="20px">
            <div fxFlex="30">
                <p class="u--text-12">{{ 'CATEGORY_FORM_ICON'|translate }}</p>
                <app-add-media [type]="'cat_sm'" (onEndItem)="onFileEvent($event, 'icon')"
                               (onStartUploadItem)="onFileEvent($event, 'icon')"></app-add-media>
            </div>
            <div fxFlex="30">
                <p class="u--text-12">{{ 'CATEGORY_FORM_IMAGE'|translate }}</p>
                <app-add-media [type]="'cat_sm'" (onEndItem)="onFileEvent($event, 'img')"
                               (onStartUploadItem)="onFileEvent($event, 'img')"></app-add-media>
            </div>
            <div fxFlex="60">
                <p class="u--text-12">Baner</p>
                <app-add-media [type]="'cat_sm'" (onEndItem)="onFileEvent($event, 'banner_url')"
                               (onStartUploadItem)="onFileEvent($event, 'banner_url')"></app-add-media>
            </div>
        </div>
        <div class="form-group form-group--full-width" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start start"
             fxLayoutWrap>
            <div fxFlex="30">
                <md-select [formControl]="form.get('status')" placeholder="Status">
                    <md-option *ngFor="let status of catStates" [value]="status.value">{{ status.label|translate }}
                    </md-option>
                </md-select>
                <app-error-message [control]="form.controls['status']"
                                   [apiError]="formErrorrs?.email"></app-error-message>
            </div>
            <div fxFlex="30">
                <md-select formControlName="is_grouped" class="mat-short" placeholder="Grupowa">
                    <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}
                    </md-option>
                </md-select>
                <app-error-message [control]="form.controls['is_grouped']"
                                   [apiError]="formErrorrs?.email"></app-error-message>
            </div>
            <div fxFlex="30">
                <md-input-container class="color-picker__container">
                <span [style.background]="currentColor" class="color-picker__color-box"
                      (click)="isColorPickerOpen = true"></span>
                    <input mdInput type="text" formControlName="color" [(cpToggle)]="isColorPickerOpen"
                           [placeholder]="'CATEGORY_COLOR'|translate"
                           [cpFallbackColor]="'#FFF'" [colorPicker]="currentColor"
                           (colorPickerChange)="onColorPickerChange($event)"
                           [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'"/>

                </md-input-container>
                <app-error-message [control]="form.controls['color']"
                                   [apiError]="formErrorrs?.color"></app-error-message>
            </div>
            <div fxFlex="80">
                <md-input-container>
                    <input type="text" mdInput formControlName="description" placeholder="Opis">

                </md-input-container>
                <app-error-message [control]="form.controls['description']"
                                   [apiError]="formErrorrs?.description"></app-error-message>
            </div>
            <div fxFlex="80">
                <md-input-container>
                    <input type="text" mdInput formControlName="banner_target_url" placeholder="Url banera">

                </md-input-container>
                <app-error-message [control]="form.controls['banner_target_url']"
                                   [apiError]="formErrorrs?.banner_target_url"></app-error-message>
            </div>
        </div>
        <div class="form-group" *ngIf="showSub">
            <div>
                <app-group-category-autocomplete (categorySelected)="childAdded($event)" filterType="addNewCategory"
                                                 [placeholder]="'ADD_SUBCAEGORIES'|translate"
                                                 [category]="subcategoriesTmp"></app-group-category-autocomplete>
            </div>
            <md-chip-list selectable="false">
                <md-chip class="md-chip--small md-chip--ellipsis md-chip--icon"
                         *ngFor="let child of subcategoriesTmp;let i = index"
                         color="accent"
                         selected="true"><span>{{child.label}}</span>
                    <md-icon (click)="removeChild(i)">close</md-icon>
                </md-chip>
            </md-chip-list>

        </div>
        <div class="form-group">
            <div class="btn__group u--text-right">
                <button md-button md-dialog-close color="warn">{{ 'CANCEL'|translate }}</button>
                <button md-button md-raised-button type="submit" color="primary"
                        [disabled]="formPending || isFilePending">
                    <md-progress-spinner mode="indeterminate" color="accent"
                                         class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                         [ngClass]="{'md-spinner--show': formPending}"></md-progress-spinner>
                    {{ 'SAVE'|translate }}
                </button>
            </div>
        </div>
    </form>
</div>

