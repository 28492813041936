<h2 md-dialog-title>{{ 'NEW_ADMIN_ACOUNT'|translate }}</h2>
<form [formGroup]="newAdminForm" (submit)="submit()">
    <div>
        <md-input-container class="u--full-width">
            <input name="email"
                   type="email"
                   id="email"
                   formControlName="email"
                   mdInput
                   placeholder="{{ 'EMAIL'|translate }}"
            >
        </md-input-container>
        <app-error-message [control]="newAdminForm.controls['email']"
                           [apiError]="formErrorrs?.email"></app-error-message>
    </div>
    <app-error-message [control]="newAdminForm" [apiError]="formErrorrs?.non_field_errors"></app-error-message>
    <div class="form-group">
        <div class="btn__group u--text-right">
            <button md-button md-dialog-close color="warn">{{ 'CANCEL'|translate }}</button>
            <button color="primary" md-raised-button type="submit"
                    [disabled]="pending">
                <md-progress-spinner mode="indeterminate" color="accent"
                                     class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                     [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>
                {{ 'SUBMIT'|translate }}
            </button>
        </div>
    </div>
</form>
