<div class="authentication__inner">
    <section class="authentication__section">
        <div class="form-logo"></div>

        <div [ngSwitch]="!!isSend">
            <div *ngSwitchCase="true">
                <p class="congratulation u--text-white u--text-center">{{ 'CONGRATULATIONS'|translate }}</p>
                <p class="congratulation-msg u--text-white">{{ 'SET_PASSWORD_MSG'|translate }} <a routerLink="/login">{{ 'LOGIN_PAGE'|translate }}</a></p>
            </div>

            <form *ngSwitchDefault [formGroup]="form" (ngSubmit)="onSubmit()" class="authentication-form" autocomplete="off">
                <p class="u--text-white authentication__section_title">{{ 'CREATE_PASSWORD'|translate }}</p>
                <div>
                    <md-input-container class="u--full-width">
                        <i class="material-icons">email</i>
                        <input name="new_password1"
                               id="new_password1"
                               type="password"
                               formControlName="new_password1"
                               mdInput
                               placeholder="{{ 'NEW_PASSWORD'|translate }}"
                        >
                    </md-input-container>
                    <app-error-message [control]="form.controls['new_password1']"
                                       [apiError]="formErrorrs?.new_password1"></app-error-message>
                </div>
                <div>
                    <md-input-container class="u--full-width">
                        <i class="material-icons">lock</i>
                        <input name="new_password2"
                               id="new_password2"
                               type="password"
                               formControlName="new_password2"
                               mdInput
                               placeholder="{{ 'REPEAT_PASSWORD'|translate }}"
                        >
                    </md-input-container>
                    <app-error-message [control]="form.controls['new_password2']"
                                       [apiError]="formErrorrs?.new_password2"></app-error-message>
                </div>
                <button class="btn--full-width" color="primary" md-raised-button [disabled]="pending">
                    <md-progress-spinner mode="indeterminate" color="accent"
                                         class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                         [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>
                    {{ 'LOGIN'|translate }}
                </button>
                <app-error-message [control]="form" [apiError]="formErrorrs?.non_field_errors"></app-error-message>
            </form>
        </div>
        <span class="authentication__credit">2016 © QuizHouse - Admin Dashboard. </span>
    </section>
</div>
