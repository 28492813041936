<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" [formGroup]="filterForm">
    <div>
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start center" [formGroup]="filterForm" fxLayoutGap="10px">
            <div fxFlex="110px">
                <md-input-container>
                    <input type="text" mdInput placeholder="{{ 'SEARCH_QUESTION'|translate }}"
                           formControlName="text">
                </md-input-container>
            </div>
            <div fxFlex="200px">
                <app-category-autocomplete-form formControlName="category"></app-category-autocomplete-form>
            </div>
            <div fxFlex="110px">
                <md-select placeholder="{{ 'RELEASE'|translate }}" formControlName="is_active">
                    <md-option [value]="null">{{'ALL'|translate}}</md-option>
                    <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}
                    </md-option>
                </md-select>
            </div>
            <div fxFlex="110px">
                <md-select placeholder="{{ 'CONTAINS_NOTES'|translate }}" formControlName="contains_notes">
                    <md-option [value]="null">{{'ALL'|translate}}</md-option>
                    <md-option value="true">{{'WITH_NOTES'|translate}}</md-option>
                    <md-option value="false">{{'WITHOUT_NOTES'|translate}}</md-option>
                </md-select>
            </div>
            <div fxFlex="110px">
                <md-select placeholder="{{ 'ADDED_BY_USERS'|translate }}" formControlName="added_by_user">
                    <md-option [value]="null">{{'ALL'|translate}}</md-option>
                    <md-option value="true">{{'YES'|translate}}</md-option>
                    <md-option value="false">{{'NO'|translate}}</md-option>
                </md-select>
            </div>
            <div fxFlex="110px">
                <md-select placeholder="{{ 'ACCEPTED'|translate }}" formControlName="is_accepted"
                           style="width: 100%">
                    <md-option [value]="null">{{'ALL'|translate}}</md-option>
                    <md-option value="true">{{'YES'|translate}}</md-option>
                    <md-option value="false">{{'NO'|translate}}</md-option>
                </md-select>
            </div>
        </div>
    </div>
    <div>
        <button class="" md-button (click)="onClearClick()" *ngIf="filterForm?.dirty" color="warn">{{'CLEAR'
            |translate}}
        </button>
    </div>
</div>
