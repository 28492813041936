<div>
    <form [formGroup]="loginForm" (submit)="onSubmit($event)" autocomplete="off" class="authentication-form">
        <div>
            <md-input-container class="u--full-width">
                <i class="material-icons">email</i>
                <input name="email"
                       id="email"
                       type="email"
                       formControlName="email"
                       mdInput
                       placeholder="{{ 'EMAIL'|translate }}"
                >
            </md-input-container>
            <app-error-message [control]="loginForm.controls['email']"
                               [apiError]="formErrors?.email"></app-error-message>
        </div>
        <div>
            <md-input-container class="u--full-width">
                <i class="material-icons">lock</i>
                <input name="password"
                       type="password"
                       id="password"
                       formControlName="password"
                       mdInput
                       placeholder="{{ 'PASSWORD'|translate }}"
                >
            </md-input-container>
            <app-error-message [control]="loginForm.controls['password']"
                               [apiError]="formErrors?.password"></app-error-message>
        </div>

        <button class="btn--full-width" color="primary" md-raised-button type="submit"
                [disabled]="pending">
            <md-progress-spinner mode="indeterminate" color="accent"
                                 class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                 [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>
            {{ 'LOGIN'|translate }}
        </button>
        <app-error-message [control]="loginForm" [apiError]="formErrors?.non_field_errors"></app-error-message>
    </form>
</div>
