<section class="u--text-right">
    <div class="pagination--inline pagination__rows">
        {{'ROWS_PER_PAGE'|translate}}
        <md-select [ngModel]="itemsPerPage" (change)="onSelectChange($event)">
            <md-option *ngFor="let element of elementsOnPage" [value]="element">
                {{element}}
            </md-option>
        </md-select>
    </div>
    <div class="pagination--inline">
        <pagination-controls
                (pageChange)="onPageChangeE($event)"
                previousLabel=""
                nextLabel=""
        ></pagination-controls>
    </div>
</section>
