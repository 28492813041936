<md-input-container>
  <input mdInput placeholder="{{ 'CATEGORY'|translate }}" [mdAutocomplete]="auto"
         (blur)="categoryBlur()"
         [(ngModel)]="categoryName"
         (ngModelChange)='changed($event)'
         (focus)="categoryFocus($event)">
</md-input-container>
<md-autocomplete #auto="mdAutocomplete" [displayWith]="displayFn">
  <md-option *ngFor="let category of filteredCat | async" [value]="category">
    {{ category.label }}
  </md-option>
</md-autocomplete>
