<div class="modal-form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-form__header">
            <input type="text" formControlName="text" placeholder="Tu wpisz pytanie...">
            <app-error-message [control]="form.controls['text']"
                               [apiError]="formErrors?.text"></app-error-message>
        </div>

        <div class="form-group">
            <p class="u--text-12">{{ 'PHOTO'|translate }}</p>
            <app-add-media (onEndItem)="onFileEvent($event)" (onStartUploadItem)="onFileEvent($event)"></app-add-media>
            <app-error-message [control]="form.controls['photo_url']"
                               [apiError]="formErrors?.photo_url"></app-error-message>
        </div>
        <div class="form-group form-group--full-width" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start start">
            <div fxFlex="60">
                <app-group-category-autocomplete [placeholder]="'CATEGORY'|translate"
                                                (categorySelected)="childAdded($event)"
                                                filterType="editQuestion"></app-group-category-autocomplete>
                <app-error-message [control]="form.controls['category_id']"
                                   [apiError]="formErrors?.category_id"></app-error-message>
            </div>
            <div fxFlex="20">
                <md-select formControlName="is_active" placeholder="Opublikowane" class="mat-short">
                    <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}
                    </md-option>
                </md-select>
                <app-error-message [control]="form.controls.is_active"></app-error-message>
            </div>
        </div>
        <div class="form-group form-group__answers" formArrayName="answers" fxLayout="column" fxLayoutGap="10px">
            <div *ngFor="let answer of getAnswers(form); let i=index" fxFlex>
                <md-input-container class="block" [formGroupName]="i">
                    <input type="text" mdInput
                           placeholder="{{ (i === 0 ? 'CORRECT_ANSWER' : 'ANSWER_NR')|translate:{value: i+1} }}"
                           formControlName="text">
                </md-input-container>
                <app-error-message [control]="form.controls.answers.controls[i].controls['text']"></app-error-message>
            </div>
        </div>
        <div class="form-group">
            <div class="btn__group u--text-right">
                <button md-button md-dialog-close color="warn">{{ 'CANCEL'|translate }}</button>
                <button md-button md-raised-button type="submit" color="primary"
                        [disabled]="isFilePending || formPending">
                    <md-progress-spinner mode="indeterminate"
                                         class="md-spinner--sm md-spinner--inline md-spinner--white md-spinner--animated-show"
                                         [ngClass]="{'md-spinner--show': formPending}"></md-progress-spinner>
                    {{ 'SAVE'|translate }}
                </button>
            </div>
        </div>
    </form>
</div>

