<div class="modal-form categories-order-modal">
    <div class="modal-form__header">

        <h3 class="u--text-white">Zmień kolejność</h3>
    </div>
    <p class="u--text-error" *ngIf="errors">{{errors|json}}</p>
    <div #autoscroll class="autoscroll-wrapper">
        <app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
        <div [dragula]='"dragbag"' [dragulaModel]='collection'>
            <ng-container *ngFor="let item of collection; let i = index">
                <div class="categories-order__item handle" [attr.data-index]="i" [hidden]="!item.is_active">
                    <md-icon>drag_handle</md-icon>
                    <app-image-preview-with-upload [imgSrc]="item.icon"
                                                type="'cat_sm'"></app-image-preview-with-upload>
                    <p>{{item.label}}</p>
                </div>
            </ng-container>
        </div>
    </div>
</div>
