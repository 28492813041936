<div class="image-prev" [ngClass]="{'image-prev--edit-mode': isEdit}">
<span [ngSwitch]="isEdit && isPending">
    <div *ngSwitchCase="true" class="image-prev__progress">
             <md-progress-spinner color="warn" [value]="progress">
    </md-progress-spinner>
        <p> {{progress}}%</p>
    </div>
    <div *ngSwitchDefault class="image-prev__inner" [style.backgroundImage]="imgSrc ? 'url('+(imgSrc)+')' : null">

</div>
</span>
    <div class="image-prev__dragDrop"
         ng2FileDrop
         *ngIf="isEdit"
         [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
         (fileOver)="fileOverBase($event)"
         [uploader]="uploader"
    >
        <label><input type="file" ng2FileSelect [uploader]="uploader"></label>
    </div>
</div>
<md-icon class="u--pointer image-prev__delete" (click)="onDeleteClick($event)" *ngIf="imgSrc && isEdit">delete</md-icon>
