<md-toolbar color="accent" class="u--shadow" [@slideFadeTopUser]>
    <button md-icon-button (click)="onToggleMenu()">
        <md-icon class="u--font-weight--400 md-icon-sm">menu</md-icon>
    </button>
    <span>{{title}}</span>
     <span *ngIf="navService.navToolbartxt" style="margin-left:5px">
        {{navService.navToolbartxt}}
    </span>
    <span class="u--spacer"></span>
    <button md-icon-button [mdMenuTriggerFor]="menu">
        <md-icon>more_vert</md-icon>
    </button>
    <md-menu #menu="mdMenu">
        <button md-menu-item (click)="openDialog()">{{ 'CHANGE_PASSWORD'|translate }}</button>
        <button md-menu-item (click)="onLogout()">
            <!--<md-icon>notifications_off</md-icon>-->
            <span>{{ 'LOGOUT'|translate }}</span>
        </button>
    </md-menu>
</md-toolbar>
