<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
<div class="u--shadow list__view filters">

    <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
        <div>
            <app-categories-filters (onFilterChange)="onFilterChange($event)"></app-categories-filters>
        </div>
        <div class="u--text-right">
            <div fxFlex class="u--text-right">
                <a md-button md-raised-button color="primary" (click)="orderModal()">{{ 'ORDER'|translate }}</a>
                <a md-button md-raised-button color="primary" routerLink="/categories/add-new"><md-icon class="md-icon--sm">add</md-icon>{{ 'ADD_CATEGORY'|translate }}</a>
            </div>
        </div>
    </div>
    <table class="table table--align-left table--white table--editable">
        <thead>
        <tr>
            <th>{{'BIG_IMAGE'|translate}}</th>
            <th>{{'SMALL_IMAGE'|translate}}</th>
            <th>{{'BANNER'|translate }}</th>
            <th>{{'URL_ADDRESS'|translate }}</th>
            <th>{{'CATEGORY_COLOR'|translate}}</th>
            <th>{{'CATEGORY'|translate}}</th>
            <th>{{'DESCRIPTION'|translate}}</th>
            <th>{{'STATUS'|translate}}</th>
            <th>{{'RELEASE'|translate}}</th>
            <th>{{'GROUP_CAT'|translate}}</th>
            <th>{{'SLUG'|translate}}</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let category of collection| paginate: { itemsPerPage:pagination.limit, totalItems:collectionCount, currentPage: page }; let i = index" [attr.data-index]="i" app-category-row [row]="category" [order]="category.order" class="table__tr" (click)="onRowClick(i)" (onDelete)="onDeleteCat(category)">
        </tr>
        </tbody>
    </table>
    <app-pagination [itemsPerPage]="pagination.limit" (onPageChange)="onPageChange($event)"
                    (onNumPagesChange)="onNumPagesChange($event)"></app-pagination>
    <router-outlet></router-outlet>
</div>
