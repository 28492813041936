<td>{{ user.name }}</td>
<td>{{ user.email }}</td>
<td>{{ user.register_type }}</td>
<td>
    <div class="image-prev">
        <div class="image-prev__inner" [ngStyle]="{'background-image': user.avatar.thumb ? 'url('+(user.avatar.thumb)+')' : null}"></div>
    </div>
</td>
<td>
    {{ user.reports_count }}
</td>
<td>
    <md-icon class="u--pointer" (click)="deleteUser()">delete</md-icon>
</td>
