<h2 md-dialog-title>{{catName}}</h2>

<md-dialog-content>
    <p class="u--text-error" style="max-width: 450px">{{'DELETE_CATEGORY_TXT'|translate}}</p>
    <div>
        <md-input-container class="u--full-width">
            <input name="label"
                   type="text"
                   id="label"
                   mdInput
                   [formControl]="catNameForm"
                   [placeholder]="'DELETE_CATEGORY_COINFIRM_TXT'|translate"
            >
        </md-input-container>
        <app-error-message [control]="catNameForm"
                           [apiError]="formErrors?.non_field_errors"></app-error-message>
    </div>
</md-dialog-content>

<md-dialog-actions>
    <div class="btn__group u--text-right u--width-100">
        <button md-dialog-close md-raised-button>{{'NO' | translate}}</button>
        <button md-raised-button color="warn" (click)="onSubmitClick()" [disabled]="isButtonDisable"> <md-progress-spinner mode="indeterminate" color="accent"
                                                                                                                           class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                                                                                                           [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>{{'YES' | translate}}</button>
    </div>
</md-dialog-actions>
