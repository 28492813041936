<div class="add-media-box__dragDrop"
     ng2FileDrop
     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
     (fileOver)="fileOverBase($event)"
     [uploader]="uploader"
>
    <span [ngSwitch]="!!isPending">
    <div *ngSwitchCase="true" class="add-media-box__progress">
             <md-progress-spinner color="accent" [value]="progress">
    </md-progress-spinner>
        <p> {{progress}}%</p>
    </div>

    <div *ngSwitchDefault class="add-media-box__inner"
         [ngStyle]="{'background-image': imgSrc ? 'url('+(imgSrc)+')' : null}">
         <label><input type="file" ng2FileSelect [uploader]="uploader"></label>
</div>
</span>
</div>






