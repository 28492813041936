<div class="modal-form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isInit">
        <div class="modal-form__header">
            <input type="text" formControlName="title" placeholder="Tytuł konkursu">
            <app-error-message [control]="form.controls['title']"
                               [apiError]="formErrors?.title"></app-error-message>
        </div>
        <div class="form-group form-group--full-width" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start start"
             fxLayoutWrap>
            <div fxFlex="80">
                <md-input-container>
                    <input type="text" mdInput formControlName="description" placeholder="Opis">

                </md-input-container>
                <app-error-message [control]="form.controls['description']"
                                   [apiError]="formErrors?.description"></app-error-message>
            </div>
            <div fxFlex="80">
                <md-input-container>
                    <input type="text" mdInput formControlName="terms_url" placeholder="Regulamin">

                </md-input-container>
                <app-error-message [control]="form.controls['terms_url']"
                                   [apiError]="formErrors?.terms_url"></app-error-message>
            </div>
            <div fxFlex="80">
                <md-input-container class="color-picker__container">
                    <span [style.background]="currentColor" class="color-picker__color-box"
                          (click)="isColorPickerOpen = true"></span>
                        <input mdInput type="text" formControlName="color"
                        [(cpToggle)]="isColorPickerOpen"
                        [placeholder]="'CATEGORY_COLOR'|translate"
                        [cpFallbackColor]="'#FFF'" [colorPicker]="currentColor"
                        (colorPickerChange)="onColorPickerChange($event)"
                        [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'"/>

                    </md-input-container>
                    <app-error-message [control]="form.controls['color']"
                                       [apiError]="formErrorrs?.color"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <md-input-container>
                    <input mdInput [mdDatepicker]="minDatePicker" (click)="minDatePicker.open()"
                           formControlName="start_date" placeholder="Start">
                    <button mdSuffix [mdDatepickerToggle]="minDatePicker"></button>
                </md-input-container>
                <md-datepicker #minDatePicker [touchUi]="touch"></md-datepicker>
                <app-error-message [control]="form.controls['start_date']"
                                   [apiError]="formErrors?.start_date"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <md-input-container>
                    <input mdInput [mdDatepicker]="maxDatePicker" formControlName="end_date" placeholder="Koniec"
                           (click)="maxDatePicker.open()">
                    <button mdSuffix [mdDatepickerToggle]="maxDatePicker"></button>
                </md-input-container>
                <md-datepicker #maxDatePicker [touchUi]="touch"></md-datepicker>
                <app-error-message [control]="form.controls['end_date']"
                                   [apiError]="formErrors?.end_date"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <md-input-container>
                    <input type="text" mdInput formControlName="min_won_answers" placeholder="Ilość prawidłowych odpowiedzi">

                </md-input-container>
                <app-error-message [control]="form.controls['min_won_answers']"
                                   [apiError]="formErrors?.min_won_answers"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <md-input-container>
                    <input type="text" mdInput formControlName="max_vouchers_per_user"
                           placeholder="Ilośc voucherów na użytkownika">

                </md-input-container>
                <app-error-message [control]="form.controls['max_vouchers_per_user']"
                                   [apiError]="formErrors?.max_vouchers_per_user"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <div formArrayName="categories" *ngFor="let category of form.get('categories').controls; let i = index;">
                    <div class="form-group--full-width"
                         fxLayoutAlign="start start"
                         fxLayoutWrap [formGroupName]="i">

                        <app-group-category-autocomplete [placeholder]="'CATEGORY'|translate"
                                                         [category]="selectedCategory"
                                                         filterType="contest"
                                                         (categorySelected)="categoryAdded($event)"></app-group-category-autocomplete>
                    </div>

                </div>
                <app-error-message [control]="form.controls['categories']"
                                   [apiError]="formErrors?.categories"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <md-select formControlName="is_active" placeholder="Opublikowane" class="mat-short">
                    <md-option *ngFor="let active of actives" [value]="active.value">{{ active.label|translate }}
                    </md-option>
                </md-select>
                <app-error-message [control]="form.controls.is_active"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <md-input-container>
                    <input type="text" mdInput formControlName="organizer_name"
                           placeholder="Organizator">

                </md-input-container>
                <app-error-message [control]="form.controls['organizer_name']"
                                   [apiError]="formErrors?.organizer_name"></app-error-message>
            </div>
            <div fxFlex="37.5">
                <p class="u--text-12">{{ 'COVER'|translate }}</p>
                <app-image-preview-with-upload [imgSrc]="coverUrl" type="'cat_lg'" [isEdit]="true" (onEndItem)="onFileEvent($event)"></app-image-preview-with-upload>
                <app-error-message [control]="form.controls['cover_url']"
                                   [apiError]="formErrors?.cover_url"></app-error-message>
            </div>
        </div>

        <div class="form-group">
            <div class="btn__group u--text-right">
                <button md-button md-dialog-close color="warn">{{ 'CANCEL'|translate }}</button>
                <button md-button md-raised-button type="submit" color="primary"
                        [disabled]="formPending || isFilePending">
                    <md-progress-spinner mode="indeterminate" color="accent"
                                         class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                         [ngClass]="{'md-spinner--show': formPending}"></md-progress-spinner>
                    {{ 'SAVE'|translate }}
                </button>
            </div>
        </div>
    </form>
</div>

