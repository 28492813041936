<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center"  [formGroup]="filterForm">
    <div>
        <md-input-container>
            <input type="text" mdInput placeholder="{{ 'SEARCH_ADMIN'|translate }}"  formControlName="text">
        </md-input-container>
    </div>
    <div>
        <button class="" md-button (click)="onClearClick()" *ngIf="filterForm?.dirty" color="warn">{{'CLEAR' |translate}}</button>
    </div>
</div>
