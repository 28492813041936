<app-overflow-spinner [showSpinner]="pending"></app-overflow-spinner>
<div class="u--shadow list__view u--bg-white">
    <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="list__navbar u--bg-white">
        <div>
            <app-users-filter (onFilterChange)="onFilterChange($event)"></app-users-filter>
        </div>
    </div>
    <table class="table table--white ">
        <thead class="table__head u--text-left">
        <tr>
            <th>{{'NICK'|translate}}</th>
            <th>Email</th>
            <th>Type</th>
            <th>{{'IMAGE'|translate}}</th>
            <th colspan="2">{{'REPORTS_NUMBER'|translate}}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr  *ngFor="let item of collection| paginate: { itemsPerPage:pagination.limit, totalItems:collectionCount, currentPage: page }; let i = index" app-user-row [user]="item"
            (onDelete)="onDeleteUserClick($event)"></tr>
        </tbody>
    </table>
    <app-pagination *ngIf="isInit"  [itemsPerPage]="pagination.limit" (onPageChange)="onPageChange($event)" (onNumPagesChange)="onNumPagesChange($event)"></app-pagination>
    <router-outlet></router-outlet>
</div>


