<h2 md-dialog-title>{{'DELETE' | translate}}</h2>
  <md-dialog-content><p [innerHTML]="label|translate:value"></p></md-dialog-content>
  <md-dialog-actions>
      <div class="btn__group u--text-right u--width-100">
          <button md-dialog-close md-raised-button>{{'CANCEL' | translate}}</button>
          <button md-raised-button color="warn" (click)="onDeleteClick()" [disabled]="pending">
              <md-progress-spinner mode="indeterminate" color="accent"
                                   class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                   [ngClass]="{'md-spinner--show': pending}"></md-progress-spinner>{{'DELETE' | translate}}</button>
      </div>
  </md-dialog-actions>