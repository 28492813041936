<h2 md-dialog-title>Import CSV</h2>

<p>Dodaj plik z voucherami do konkursu {{contest.title}}</p>
<section class="import-file__view u--bg-white">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px">
        <div fxFlex fxFlexFill>
            <div [ngSwitch]="uploader.queue.length > 0">
                <div *ngSwitchCase="true">
                    <p class="selected-file__label">Wybrany plik: <a (click)="removeFile()">
                        <md-icon class="md-icon--sm">delete</md-icon>
                    </a></p>
                    <p class="selected-file">{{uploader.queue[0].file.name}} </p>
                </div>
                <div *ngSwitchDefault>
                    <div class="import-file__drag"
                         ng2FileDrop
                         [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                         (fileOver)="fileOverBase($event)"
                         [uploader]="uploader"
                    >
                        <span class="import-file__drag-txt">Przeciągnij plik lub</span>

                        <button md-button md-raised-button class="selectfile-btn" color="primary">
                            <md-icon class="md-icon--sm">add</md-icon>
                            {{ 'WYBIERZ'|translate }}
                            <label for="fileUpload">
                                <input id="fileUpload" type="file" ng2FileSelect [uploader]="uploader">
                            </label>
                        </button>

                    </div>
                    <p class="file-error">{{fileError}}</p>
                    <app-error-message [control]="formGroup.controls['file']"></app-error-message>
                </div>
            </div>

            <div class="import-file__progress" *ngIf="isPending">
                <p>{{progress}}%</p>
                <span [ngStyle]="{'width.%': progress}"></span>
            </div>
        </div>

    </div>
    <div [ngSwitch]="currentStatus" class="import-file__status">
            <span *ngSwitchCase="IMPORT_STATUS.SUCCESS">
                <h3>Vouchery dodane</h3>
                <p>dodano {{collection.added}} z {{collection.added + collection.skipped}}</p>
            </span>
        <span *ngSwitchCase="IMPORT_STATUS.NOT_ALL">
                <h3>Nie wszystkie pytania zostały wczytane</h3>
            <p>Poniższe pytania zawierały błędy i nie zostały dodane do bazy danych</p>
            </span>
        <span *ngSwitchCase="IMPORT_STATUS.ERROR">
                <h3>Podczas wczytywania kodw nastąpił błąd</h3>
            </span>
        <span *ngSwitchDefault></span>
    </div>

</section>
<md-dialog-actions>
    <div class="btn__group u--text-right u--width-100">
        <button md-dialog-close md-raised-button [disabled]="isSendBtnDisabled" >{{'Zamknij' | translate}}</button>
        <button md-button md-raised-button class="new-question-btn" color="primary"
                [disabled]="isSendBtnDisabled" (click)="onSendFile()">
            <md-progress-spinner mode="indeterminate" color="accent"
                                 class="md-spinner--sm md-spinner--inline md-spinner--animated-show"
                                 [ngClass]="{'md-spinner--show': isPending}"></md-progress-spinner>
            Wyślij
        </button>
    </div>
</md-dialog-actions>`
